/**
 * A simple pagination component
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
import React from 'react';
import classNames from 'classnames';
import * as styles from './pagination.module.scss'
import PropTypes from 'prop-types';

const PaginationLink = ({num, currentPage, setPage, className, ...props}) => {
    const classes = classNames({
        [styles.item]: true,
        [styles.active]: num === currentPage,
        [className]: !!className,
    });

    let label = num;
    if (num < 1) {
        label = !num ? <>&larr;</> : <>&rarr;</>;
    }

    let clickTrigger = () => setPage(num);
    if (num < 1) {
        clickTrigger = !num
            ? () => setPage(currentPage - 1)
            : () => setPage(currentPage + 1);
    }

    return (<div className={classes} onClick={clickTrigger} {...props}>{label}</div>);
}

PaginationLink.propTypes = {
    num: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default PaginationLink;
