/**
 * Component to show a blog-list
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Peritus
import {ResponsiveImageProps} from '../ResponsiveImage';
import Block from '../Block';
import BlogPostCard from '../BlogPostCard';

// Styles
import * as styles from './blog-list.module.scss';
import {wide} from '../Block/block.module.scss';

const BlogList = ({posts}) => (
    <Block className={classNames({[styles.blogList]: true, [wide]: true})}>
        {posts && posts.map((post, key) => (
            <BlogPostCard key={key} post={post}/>
        ))}
    </Block>
);

BlogList.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        date: PropTypes.string,
        dateString: PropTypes.string,
        acf: PropTypes.shape({
            preview: PropTypes.shape({
                excerpt: PropTypes.string,
                thumbnailId: PropTypes.shape(ResponsiveImageProps),
            }),
        }),
    })).isRequired,
};

export default BlogList;
