/**
 * A simple pagination component
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
import React from 'react';
import classNames from 'classnames';
import * as styles from './pagination.module.scss'
import PaginationLink from './PaginationLink';

const Pagination = ({maxElements, postsPerPage, setPage, currentPage}) => {
    let links = [];
    const pageCount = Math.ceil(maxElements / postsPerPage);
    const firstPage = 1;
    const lastPage = pageCount;

    const linkProps = {
        setPage: setPage,
        currentPage: currentPage,
    };

    const dotsClasses = classNames({
        [styles.item]: true,
        [styles.dots]: true
    })

    if (pageCount <= 1) {
        return null;
    }

    // Show < if not on first page
    if (currentPage !== 1) {
        links.push(<PaginationLink className={styles.arrow} num={0} {...linkProps}/>);
    }

    // Always show the first page
    links.push(<PaginationLink num={firstPage} {...linkProps}/>);

    // show dots if needed
    if (currentPage > (firstPage + 2) && pageCount > 4) {
        links.push(<div className={dotsClasses}>...</div>);
    }

    // Show intermediate pages
    if (currentPage === firstPage) {
        if (pageCount > 2) {
            links.push(<PaginationLink num={currentPage + 1} {...linkProps}/>)
        }

        if (pageCount > 3) {
            links.push(<PaginationLink num={currentPage + 2} {...linkProps}/>)
        }
    } else if (currentPage === lastPage) {
        if (pageCount > 3) {
            links.push(<PaginationLink num={currentPage - 2} {...linkProps}/>)
        }

        if (pageCount > 2) {
            links.push(<PaginationLink num={currentPage - 1} {...linkProps}/>)
        }
    } else {
        if ((currentPage - 1) > firstPage) {
            links.push(<PaginationLink num={currentPage - 1} {...linkProps}/>)
        }

        links.push(<PaginationLink num={currentPage} {...linkProps}/>)

        if ((currentPage + 1 < lastPage)) {
            links.push(<PaginationLink num={currentPage + 1} {...linkProps}/>)
        }
    }

    // show dots if needed
    if (currentPage < (lastPage - 2) && pageCount > 4) {
        links.push(<div className={dotsClasses}>...</div>);
    }

    // always show the last page
    links.push(<PaginationLink num={lastPage} {...linkProps}/>);

    // show arrow if not on last page
    if (currentPage !== lastPage) {
        links.push(<PaginationLink className={styles.arrow} num={-1} {...linkProps}/>)
    }

    return (<div className={styles.pagination}>{links}</div>);
}

export default Pagination;
