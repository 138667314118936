/**
 * Our blog-post-page
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Seo, {SeoShape} from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {BlogList} from '../../../components/BlogList';
import {LangShape} from '../../../components/Languages';
import Pagination from '../../../components/Pagination';

const BlogPage = ({data, Blocks}) => {
    const [page, setPage] = useState(1);

    const {
        uri,
        seo,
        language,
        translations,
        acf: {
            pagebuilder: {
                layouts,
            },
        },
    } = (data && data.postsPage);

    const posts = (data && data.posts.nodes);
    let postsPerPage = 9;
    let firstPostIndex =  (page - 1) * postsPerPage;
    let currentPosts = posts.slice(firstPostIndex, firstPostIndex + postsPerPage);

    return (<>
        <Seo seoData={seo} translations={translations} language={language} url={uri}/>
        <Header siteTitle={seo.title} translations={translations} language={language}/>
        <main>
            {layouts && layouts.map((block, index) => (<Blocks key={index} block={block}/>))}
            <BlogList posts={currentPosts}/>
            <Pagination currentPage={page} maxElements={posts.length} postsPerPage={postsPerPage} setPage={setPage} />
        </main>
        <Footer/>
    </>);
};

BlogPage.propTypes = {
    data: PropTypes.shape({
        postsPage: PropTypes.shape({
            uri: PropTypes.string.isRequired,
            seo: SeoShape.isRequired,
            ...LangShape,
            acf: PropTypes.shape({
                pagebuilder: PropTypes.shape({
                    layouts: PropTypes.array.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
        posts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
                dateString: PropTypes.string.isRequired,
                acf: PropTypes.shape({
                    preview: PropTypes.shape({
                        excerpt: PropTypes.string.isRequired,
                    }).isRequired,
                }).isRequired
            })).isRequired
        }).isRequired,
    }).isRequired,
};

export default BlogPage;
